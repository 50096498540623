import MicroModal from 'micromodal';
const Drop = require('tether-drop')

document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.password-field').forEach((field) => {
        field.querySelector('label').addEventListener('click', function () {
            field.classList.toggle('password-visible');
            field.querySelector('input').setAttribute('type', field.querySelector('input').getAttribute('type') === 'password' ? 'text' : 'password');
        })
    })

    document.querySelectorAll('#menu-btn, .menu-top a:first-child').forEach((item) => item.addEventListener('click', function () {
        document.querySelector('body').classList.toggle('menu-open');
        document.querySelector('.menu').classList.toggle('menu-open');
    }))

    document.querySelectorAll('#chat-btn, .chat-top a:first-child').forEach((item) => item.addEventListener('click', function () {
        document.querySelector('body').classList.toggle('chat-open');
        document.querySelector('.chat').classList.toggle('chat-open');
    }))

    document.querySelectorAll('.chat-users li').forEach((li) => {
        li.addEventListener('click', () => {
            document.querySelector('body').classList.toggle('chat-messages-open');
            document.querySelector('.chat-messages').classList.toggle('chat-messages-open');
        })
    })

    document.querySelector('.chat-messages-top a:first-child').addEventListener('click', () => {
        document.querySelector('body').classList.toggle('chat-messages-open');
        document.querySelector('.chat-messages').classList.toggle('chat-messages-open');
    })

    if( document.querySelector('.backdrop') )  {
        document.querySelector('.backdrop').addEventListener('click', () => {
            document.querySelector('body').classList.value = '';
            document.querySelector('.chat-messages').classList.remove('chat-messages-open');
            document.querySelector('.chat').classList.remove('chat-open');
            document.querySelector('.menu').classList.remove('menu-open');
        })
    }

    let dropInstance = new Drop({
        target: document.querySelector('.chat-messages-top a[data-more]'),
        content: document.querySelector('.chat-messages-top [data-chat-more-drop]'),
        position: 'bottom right',
        openOn: 'click'
    })

    document.querySelectorAll('[data-more-trigger]').forEach((item) => {
        new Drop({
            target: item,
            content: document.querySelector(`[data-more-id="${item.dataset.moreTrigger}"]`),
            position: 'bottom right',
            openOn: 'click'
        })
    });

    MicroModal.init({
        awaitCloseAnimation: true,
    });

    if( document.querySelector('#target-image') ) {
        document.querySelector('#target-image').addEventListener('change', (e) => {
            let url = e.target.files[0] ?? null;
            if (url) {
                document.querySelector('#target-image-preview').classList.add('loaded');
                document.querySelector('#target-image-preview').src = URL.createObjectURL(url);
            }
        })
    }

    document.querySelectorAll('.accordion').forEach((accordion) => {
        accordion.querySelector('.accordion-title').addEventListener('click', () => {
            accordion.classList.toggle('accordion-open');
        })
    })
});